<template>
  <v-container fluid no-gutters>
    <B2cTabs/>
    <v-row>
      <v-col v-for="(restriction,index) in restrictions" :key="index" lg="3" md="3" xl="3">
        <B2CCustomerBlacklistWidget
            :key="index"
            :index="index"
            :id="restriction.id"
            :name="restriction.name"
            :status_id="restriction.status_id"
            :start_date="restriction.start_date"
            :end_date="restriction.end_date"
            @edit="openRestrictionModal"
            @delete="deleteRestriction"
            @toggleRestrictionStatus="toggleRestrictionStatus"
        ></B2CCustomerBlacklistWidget>
      </v-col>
    </v-row>
    <div class="add_btn mt-2" style="text-align: left">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              color="#00AAAAFF"
              dark
              @click="openRestrictionModal()"
              style="text-transform: none"
          >
            + Add Restriction
          </v-btn>
        </template>
        + Add new restriction
      </v-tooltip>
    </div>
    <B2CCustomerBlackListForm
        :id="editRestrictionId"
        :is-enable-blacklist-form="addRestrictionFormModal"
        @close="closeAddRestrictionForm"
    />
  </v-container>
</template>
<script>
import B2cTabs from "@/views/B2CConfigurations/B2cTabs.vue";
import B2CCustomerBlacklistWidget from "@/components/B2C/B2CCustomerBlacklistWidget.vue";
import B2CCustomerBlackListForm from "@/components/B2C/B2CCustomerBlackListForm.vue";

export default {
  name: "B2CCustomerBlacklist",
  components: {B2CCustomerBlackListForm, B2CCustomerBlacklistWidget, B2cTabs},
  data() {
    return {
      editRestrictionId: null,
      addRestrictionFormModal: false,
      restrictions: [],
    }
  },
  mounted() {
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    this.loadRestrictions();
  },
  methods: {
    openRestrictionModal(id) {
      console.log('id');
      console.log(id);
      if (id) {
        this.editRestrictionId = id;
      }
      this.addRestrictionFormModal = true;
    },
    closeAddRestrictionForm() {
      this.editRestrictionId = null;
      this.addRestrictionFormModal = false;
      this.loadRestrictions()
    },
    loadRestrictions() {
      this.showLoader();
      this.$http
          .get(
              `venues/b2c/restrictions/get`
          )
          .then((response) => {
            if (response.status == 200) {
              this.restrictions = response.data.data;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader()
      });
    },
    deleteRestriction(id){
      this.showLoader();
      this.$http
          .get(
              `venues/b2c/restrictions/delete/${id}`
          )
          .then((response) => {
            if (response.status == 200) {
              this.loadRestrictions()
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader()
      });
    },
    toggleRestrictionStatus(id){
      this.showLoader();
      this.$http
          .get(
              `venues/b2c/restrictions/toggle/${id}`
          )
          .then((response) => {
            if (response.status == 200) {
              this.loadRestrictions()
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader()
      });
    }
  }
}
</script>


<style scoped>

</style>