<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-if="button" readonly v-on="on" color="#062b48" dark block>{{
          dateFormatted
        }}</v-btn>


      <v-text-field
          v-else
          :value="dateFormatted"
          :dense="dense"
          :outlined="outlined"
          :solo="solo"
          readonly
          v-on="on"
          :rules="rules"
          hide-details="auto"
          class="q-text-field shadow-0"


      >
        <template v-slot:prepend-inner>
          <CalenderIcon style="margin-top: 4px"/>
        </template>

      </v-text-field>
    </template>
    <v-date-picker
        v-bind="$attrs"
        v-on="$listeners"
        :min="button || backFill ? null : minDate"
        :max="maxDate ? maxDate : null"
        :value="value"
        @input="emitChange"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
import CalenderIcon from "@/assets/images/misc/calender-2.svg";

export default {
  inheritAttrs: false,
  props: {
    value: { type: String, default: null },
    label: { type: String, default: "Select date" },
    dense: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
    solo: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    button: { type: Boolean, default: false },
    backFill: { type: Boolean, default: false },
    dayName: { type: Boolean, default: false },
    minDate: { type: String, default: moment().format("YYYY-MM-DD") },
    maxDate: { type: String, default: null },

  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    dateFormatted() {
      if (this.value == null) return null;
      if (this.dayName) {
        return moment(this.value, "YYYY-MM-DD").format("dddd, Do MMM YYYY");
      }
      return moment(this.value, "YYYY-MM-DD").format("Do MMM YYYY");
    },
  },
  methods: {
    emitChange(v) {
      this.$emit("input", v);
      this.menu = false;
    },
  },
  components:{
    CalenderIcon
  }
};
</script>

<style>
</style>