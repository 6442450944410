<template>
  <v-dialog persistent v-model="isEnableBlacklistForm" width="40%">
    <v-form ref="product_form" autocomplete="off">
      <v-card>
        <v-card-text class="border-bottom mb-3">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon
                    class="text-2xl font-semibold"
                    text="Add Restriction"
                    style="color: black"
                >
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-row dense class="mt-6">
            <v-row>
              <v-col cols="3" md="6" sm="12" class="pb-0">
                <label for=""> Name </label>
                <v-text-field
                    outlined
                    background-color="#fff"
                    light
                    v-model="restriction.name"
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                    required
                    :rules="[(v) => !!v || 'Tag name is required']"
                ></v-text-field>
              </v-col>


              <v-col md="6" class="pl-0">
                <label> Nationalities </label>
                <v-autocomplete
                    :items="countries"
                    v-model="restriction.countries"
                    item-value="id"
                    item-text="name"
                    outlined
                    multiple
                    background-color="#fff"
                    placeholder="Nationalities"
                    class="q-autocomplete shadow-0"
                    hide-details="auto"
                    validate-on-blur
                    dense
                    :rules="[v => (v && v.length > 0) || 'Nationality is required']"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggleSelect()">
                      <v-list-item-action>
                        <v-icon :color="restriction.countries.length > 0 ? 'teal darken-4' : ''">{{
                            getServiceIcon()
                          }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template
                      v-if="restriction.countries.length === countries.length"
                      v-slot:selection="{ index }"
                  >
                    <span v-if="index === 0">All Nationalities</span>
                  </template>
                  <template v-else v-slot:selection="{ item, index }">
                    <span v-if="index === 0">{{ item.name }}</span>
                    <span v-if="index === 1">, {{ item.name }}</span>
                    <span v-if="index === 2">, ...</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col md="4" class="">
                <label for=""> Start date </label>
                <date-picker-field
                    v-model="restriction.start_date"
                    :backFill="false"
                    :hide-details="true"
                    class="q-text-field shadow-0"
                    dense
                    label=""
                ></date-picker-field>
              </v-col>
              <v-col md="4" class="pl-0 ">
                <label for=""> End Date </label>
                <date-picker-field
                    v-model="restriction.end_date"
                    :backFill="false"
                    :hide-details="true"
                    :min-date="restriction.start_date"
                    class="q-text-field shadow-0"
                    dense
                    label=""
                ></date-picker-field>
              </v-col>
              <v-col md="3" class="pl-0 mt-7">
                <v-switch
                    class="mx-4 my-0"
                    dense
                    hide-details="auto"
                    :false-value="2"
                    :true-value="1"
                    label="Active"
                    required
                    v-model="restriction.status_id"
                ></v-switch>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close()" class="ma-2">Close</v-btn>
          <v-btn text class="ma-2 white--text blue-color" @click="saveRestriction"
          >Save
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";
import DatePickerField from "@/components/Fields/DatePickerField.vue";

export default {
  name: "B2CCustomerBlackListForm",
  components: {DatePickerField, SvgIcon},
  props: {
    isEnableBlacklistForm: {type: Boolean, default: false},
    id: {type: Number, default: null},
  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.data;
    },
  },
  watch: {
    isEnableBlacklistForm: {
      immediate: true,
      handler(val) {
        if (val && this.id) {
          this.getRestriction(this.id);
        }
      }
    }
  },
  data() {
    return {
      restriction: {
        name: '',
        countries: [],
        start_date: null,
        end_date: null,
        status_id: null,
      },
    }
  },
  methods: {
    toggleSelect() {
      this.$nextTick(() => {
        if (this.restriction.countries.length == this.countries.length) {
          this.restriction.countries = [];
        } else {
          this.restriction.countries = this.countries.map((item) => item.id);
        }
      });
      this.$forceUpdate();
    },
    getServiceIcon() {
      if (this.restriction.countries.length == 0) return "mdi-checkbox-blank-outline";
      if (this.countries.length == this.restriction.countries.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
    close() {
      this.restriction = {
        name: '',
        countries: [],
        start_date: null,
        end_date: null,
        status_id: 2,
      }
      this.$emit("close");
    },
    getRestriction(id) {
      this.showLoader();
      this.$http
          .get(
              `venues/b2c/restrictions/get/${id}`
          )
          .then((response) => {
            if (response.status == 200) {
              let data = response.data.data;
              data.countries = data.nationalities.map(nationality => nationality.id);
              this.restriction = data;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader()
      });
    },
    saveRestriction() {
      this.showLoader();
      let data = {
        id: this.restriction.id,
        name: this.restriction.name,
        countries: this.restriction.countries,
        start_date: this.restriction.start_date,
        end_date: this.restriction.end_date,
        status_id: this.restriction.status_id,
      }
      this.$http
          .post(
              `venues/b2c/restrictions/set`, data
          )
          .then((response) => {
            if (response.status == 200) {
              this.close();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader()
      });
    }
  }
}
</script>

<style scoped>

</style>