<template>
  <v-card style="border-radius: 8px" class="shadow product_card mt-5">
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <div>
            <div class="d-flex justify-space-between">
              <p class="font-semibold text-lg black--text m-b-4 mt-3 ml-2">
                <span class="line-clamp-1">{{ name }}</span>
              </p>

              <div class="align-items-center" style="margin-top: -11px">
                <LightningBoltIcon
                    :class="status_id == 1 ? '' : 'fill-red'"
                    class="pointer"
                    style="margin-right: 10px"
                    @click.stop="enableDisableRestriction"
                />
                <v-menu content-class="q-menu" right top>
                  <template v-slot:activator="{ on, attrs }">
                    <DotsIcon v-bind="attrs" v-on="on"  />
                  </template>
                  <v-list>
                    <v-list-item
                        @click="$emit('edit',id)"
                    >
                      Edit
                    </v-list-item>
                    <v-list-item
                        @click="$emit('delete', id)"
                    >
                      Delete
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <p class="font-medium m-1 p-0 ml-2">
              <span class="text-elepsis">Start date: {{ start_date | dateformat }}</span>
            </p>
            <p class="font-medium m-1 p-0 ml-2">
              <span class="text-elepsis">End date: {{ end_date | dateformat }}</span>
            </p>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import DotsIcon from "@/assets/images/misc/h-options.svg";
import LightningBoltIcon from "@/assets/images/facilities/lightning-bolt.svg";

export default {
  name: "B2CCustomerBlacklistWidget",
  components: {LightningBoltIcon, DotsIcon},
  props: {
    name: { type:String, default:''},
    status_id: { type: Number, default:null},
    index: { type: Number },
    id: { type: Number },
    start_date: null,
    end_date:null,
  },
  methods:{
    enableDisableRestriction(){
      this.$emit('toggleRestrictionStatus', this.id)
    }
  }
}
</script>


<style scoped>
.fill-red {
  fill: red !important;
}
</style>